import React from 'react'
import './TopTenCasesModule.css'

export default function TopTenCases(props) {

    const convertCategoryIdToTitle = (categoryId) => {
        switch(categoryId) {
            case '1':
                return 'Pediatrics';
            case '2':
                return 'Internal Medicine';
            case '3':
                return 'OB/GYN';
            case '4':
                return 'Neurology';
            case '5':
                return 'Emergency Medicine';
            case '6':
                return 'Psychiatry';
            default:
                return 'Invalid Category';
        }
    }

    return (
        <div className='reportCardModuleContainer topTenCasesModuleContainer'>
            <div className='reportCardModuleHeaderContainer'>
                <h2 className='reportCardModuleHeaderText'>{props.title}</h2>
            </div>
            <div className='topTenCasesTitleContainer'>
                <h3 className='topTenCasesTitleText' style={{justifySelf: 'start', paddingLeft: 10}}>Diagnosis</h3>
                <h3 className='topTenCasesTitleText'>Case Number</h3>
                <h3 className='topTenCasesTitleText'>Category</h3>
                <h3 className='topTenCasesTitleText'>Your Best Score</h3>
            </div>
            {props.userData.map((caseData, caseIndex) => (
                <div className='topTenCasesBodyContainer' key={`topTenCases-${props.title}-${caseIndex}`}>
                    <p className='topTenCasesBodyText' style={{justifySelf: 'start', paddingLeft: 10, textAlign: 'start'}}>{caseData.DiagnosisTitle}</p>
                    <p className='topTenCasesBodyText'>{caseData.CaseId}</p>
                    <p className='topTenCasesBodyText'>{convertCategoryIdToTitle(caseData.CategoryId)}</p>
                    <p className='topTenCasesBodyText'>{caseData.BestGrade}%</p>
                </div>
            ))}
        </div>
    )
}
