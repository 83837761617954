import React, { Component } from 'react'
import './UnauthenticatedHandler.css'

export class UnauthenticatedHandler extends Component {
    render() {
        return (
            <div className='authenticationErrorPageContainer' style={{height: window.innerHeight}}>
                <div className='authenticationErrorBackdrop' />
                <div className='authenticationErrorContainer'>
                    <div className='popup-header'>You have been logged out</div>
                    <div className='popup-content'>
                        <p>You are no longer logged into this session and will have to refresh and re-login.</p>
                        <p>Common causes of this are being timed out due to no response and/or someone else has logged into your account (this is not allowed if this was given voluntarily). If this was not given voluntarily, you will need to change your password.</p>
                    </div>
                    <div className='options-footer'>
                        <input className='simulation-button button-gap' type='button' value='OK' onClick={() => window.location.reload()}/>
                    </div>
                </div>
            </div>
        )
    }
}