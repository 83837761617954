import React from 'react'
import './HighYieldInformationPopup.css'

export default function HighYieldInformationPopup(props) {
    return (
        <div className='highYieldPageContainer' style={{height: window.innerHeight}}>
            <div className='highYieldBackdrop' />
            <div className='authenticationErrorContainer'>
                <div className='popup-header'>High Yield Cases</div>
                <div className='popup-content'>
                    <p>Mark High Yield if you think that this case has a high chance of being on the real USMLE Step 3 test. This will be added to a talley of all users who vote so that users can study cases that others feel are likely to be on the real test.</p>
                </div>
                <div className='options-footer'>
                    <input className='simulation-button button-gap' type='button' value='OK' onClick={props.closePopup}/>
                </div>
            </div>
        </div>
    )
}
