import getDaysFromDate from '../GetDaysFromDate'

//formatCompletedOrders accepts the array of completedOrders as an argument
//We first check for an empty object at array index 0, this indicates that there are no completedOrders

//If the check fails, we loop through completedOrders, formatting their order time and ID accordingly before returning the array
const formatCompletedOrders = (completedOrders) => {
    if (completedOrders.length > 0) {
        if (Object.keys(completedOrders[0]).length === 0 && completedOrders[0].constructor === Object) {
            completedOrders = []
        } else {
            for (let i = 0; i < completedOrders.length; ++i) {
                let reportTime = new Date(`${completedOrders[i].ReportTime}Z`)
                completedOrders[i].ReportTime = {
                    days: getDaysFromDate(reportTime),
                    hours: reportTime.getUTCHours(),
                    minutes: reportTime.getUTCMinutes()
                }

                let currentVirtualTime = new Date(`${completedOrders[i].CurrentVirtualTime}Z`)
                completedOrders[i].CurrentVirtualTime = {
                    days: getDaysFromDate(currentVirtualTime),
                    hours: currentVirtualTime.getUTCHours(),
                    minutes: currentVirtualTime.getUTCMinutes()
                }

                completedOrders[i].orderId = completedOrders[i].ID
            }
        }
    }
    return completedOrders
}

export default formatCompletedOrders