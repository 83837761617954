import React, { Component } from 'react'
import './NewCaseList.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope, faBrain, faTruckMedical, faChild, faVenus, faCheck, faBars, faCircleCheck, faCircleXmark, faCommentMedical, faArrowUpWideShort, faArrowDownShortWide, faArrowDownWideShort, faArrowUpShortWide, faArrowDownAZ, faArrowDownZA, faArrowUpAZ } from '@fortawesome/free-solid-svg-icons'
import { faHeadSideBrain, faTimer } from '@fortawesome/pro-solid-svg-icons'
import { faUserDoctor, faFileChartPie, faEyeSlash, faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import Logo from '../images/logoCropped.png'
import IncompleteCasePopup from '../Util/IncompleteCasePopup/IncompleteCasePopup';
import logFetchError from '../Functions/LogFetchError'
import fetchSubmitLogs from '../Functions/FetchSubmitLogs'
import MDSpinner from 'react-md-spinner'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import TagsPopup from '../Util/TagsPopup/TagsPopup'
import ConfirmationPopup from '../Util/ConfirmationPopup/ConfirmationPopup'

const moreOptionsPopupTransition = {
    initial: {
        opacity: 0,
        y: -10,
        x: 10,
        scale: 0.85
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        scale: 1
    },
    exit: {
        opacity: 0,
        y: -10,
        x: 10,
        scale: 0.85
    }
}

export default class NewCaseList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayIncompleteCasesPopup: false,
            pageHeight: window.innerHeight,
            pageWidth: window.innerWidth,
			incompleteCaseList: [],
			selectedIncompleteCase: 0,
			startNewCaseFunction: null,
			isCaseListReversed: false,
            unfilteredCaseList: [],
            filteredCaseList: [],
            loadingCaseList: false,
            displayMobileDrawer: false,
            tags: this.getTags(props),
            displayTagsPopup: false,
            tagsSelected: 0,
            tagsActive: [],
            popupLoading: false,
            hideCaseLoadingIdArray: [],
            reviewLaterFetchesOutstanding: [],
            confirmationPopupOptions: null,
            timedExam: true,
            caseListStyleToggleOutstanding: false,
            displayCaseMoreOptionsPopup: false,
            moreOptionsPopupPosition: {x: 0, y: 0, selectedCase: -1},
            currentSortDirection: 'Ascending',
        }
    }

    componentDidMount() {
        window.addEventListener('resize', (event) => {
            this.setState({pageHeight: window.innerHeight, pageWidth: window.innerWidth})
        })
        var metaThemeColor = document.querySelector("meta[name=theme-color]")
        metaThemeColor.setAttribute("content", this.props.selectedTheme.primaryBackgroundColor)

        this.props.setDisplayedCasesCallback(this.updateDisplayedCases)
        if (this.props.unfilteredCaseList.length > 0) {
            this.updateDisplayedCases()
        }

        if (this.props.gradingMode === 'peek') {
            this.props.setGradingMode('complete', () => {
                setTimeout(() => {
                    if (this.props.selectedCase !== -1) {
                        // scroll to selected case
                        let selectedCase = document.getElementById(`case-${this.props.selectedCase}`)
                        if (selectedCase !== null) {
                            selectedCase.scrollIntoView({block: 'center'})
                        }
                    }
                }, 100)
            })
        } else {
            setTimeout(() => {
                if (this.props.selectedCase !== -1) {
                    // scroll to selected case
                    let selectedCase = document.getElementById(`case-${this.props.selectedCase}`)
                    if (selectedCase !== null) {
                        selectedCase.scrollIntoView({block: 'center'})
                    }
                }
            }, 100)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', (event) => {
            this.setState({pageHeight: window.innerHeight})
        })
    }

    toggleTag = (tagIndex) => {
        let tags = [...this.state.tags]
        tags[tagIndex].active = !tags[tagIndex].active
        let tagsActive = [...this.state.tagsActive]

        if (tags[tagIndex].active) {
            tagsActive.push(tags[tagIndex].tag)
        } else {
            tagsActive.splice(tagsActive.indexOf(tagIndex), 1)
        }

        this.setState({tags, tagsActive}, () => {
            this.updateDisplayedCases()
        })
    }

    handlePageChange = (page) => {
        this.props.changePage(page)
    }

	viewCaseGradesClick = (e, selectedCase) => {
        let reviewLater;
        for (let i = 0; i < this.props.unfilteredCaseList.length; i++) {
            if (this.props.unfilteredCaseList[i].ID === selectedCase) {
                reviewLater = this.props.unfilteredCaseList[i].ReviewLaterFlag;
            }
        }
        this.props.viewCaseGrades(selectedCase, reviewLater);
	}

    peekCaseGrades = (e, selectedCase) => {
        let reviewLater;
        for (let i = 0; i < this.props.unfilteredCaseList.length; i++) {
            if (this.props.unfilteredCaseList[i].ID === selectedCase) {
                reviewLater = this.props.unfilteredCaseList[i].ReviewLaterFlag;
            }
        }
        this.props.peekCaseGrades(selectedCase, reviewLater);
    }

    getTags = (props) => {
        let tags = props.StaticJsonCaseList.uniquetags
        let formattedTags = []
        tags.forEach((option, index) => formattedTags.push({tag: option, selected: false, active: false, tagId: index}))
        return formattedTags
    }
    
    /*
	reviewlater api call
	*/
	fetchReviewLater = (e, caseData) => {
        e.stopPropagation()
		this.setState({reviewLaterFetchesOutstanding: [...this.state.reviewLaterFetchesOutstanding, caseData.ID]})
		fetch(`${this.props.route}/reviewlater.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.props.userData.CustomerId,
				caseId: caseData.ID,
				marked: !caseData.ReviewLaterFlag
			})
		})
		.then(response => {
			this.setState({reviewLaterFetchesOutstanding: this.state.reviewLaterFetchesOutstanding.filter((i) => i !== caseData.ID)})

			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(response => {
			this.props.reviewLaterToggle(caseData.ID);
		})
		.catch(error => {
			this.setState({reviewLaterFetchesOutstanding: this.state.reviewLaterFetchesOutstanding.filter((i) => i !== caseData.ID)})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchReviewLater')
		});
	}
	
	/*
	run the case simulation
	passes the caseData Json up to App
	*/
	beginCaseHandler = async (selectedCase) => {
		if (selectedCase) {
			for (let i = 0; i < this.props.unfilteredCaseList.length; i++) {
				if (this.props.unfilteredCaseList[i].ID === selectedCase) {
                    this.setState({loadingStartCase: true})
                    await this.props.fetchStartCase(this.props.unfilteredCaseList[i], this.incompleteStartDisplayHandler)
                    .then(() => {
                        this.setState({loadingStartCase: false})
                    })
                    .catch(() => {
                        this.setState({loadingStartCase: false})
                    })
				}
			}
		}
    }
    
    selectRandomCase = () => {
        
        let randomCase
        let randomCaseExists = false

        do {
            randomCase = this.props.unfilteredCaseList[Math.floor(Math.random() * this.props.unfilteredCaseList.length)].ID
    
            for (let i = 0; i < this.state.filteredCaseList.length; i++) {
                if (this.state.filteredCaseList[i].ID === randomCase) {
                    randomCaseExists = true
                }
            }
        } while (randomCaseExists === false)


        this.props.setSelectedCase(randomCase, () => {
            let selectedCase = document.getElementById(`case-${randomCase}`)
            if (selectedCase) {
                selectedCase.scrollIntoView({behavior: this.props.animationsDisabled ? 'auto' : 'smooth', block: 'center'})
            }
        })
    }

	incompleteStartDisplayHandler = (ialist, callBack) => {
		this.setState({displayIncompleteCasesPopup: true, startNewCaseFunction: callBack, incompleteCaseList: ialist})
	}
    
    formatCompletionDate = (rawDate) => {
        let completionDate = new Date(rawDate)

        let month = completionDate.getMonth() + 1
        let day = completionDate.getDate()
        let year = completionDate.getFullYear()

        return `${month}/${day}/${year}`
    }

    getCategoryIcon = (category) => {
        switch(category) {
            case "Internal Medicine":
                return faStethoscope
            case "Emergency Medicine":
                return faTruckMedical
            case "Psychiatry":
                return faHeadSideBrain
            case "OB/GYN":
                return faVenus
            case "Pediatrics":
                return faChild
            case "Neurology":
                return faBrain
            default:
                break;
        }
    }

    selectTag = (tagId) => {
        let tags = [...this.state.tags]
        tags[tagId].selected = !tags[tagId].selected
        let tagsSelected = this.state.tagsSelected
        let tagsActive = this.state.tagsActive

        if (tags[tagId].selected) {
            tags[tagId].active = true
            tagsSelected++
            tagsActive.push(tags[tagId].tag)
        }
        else {
            tags[tagId].active = false
            tagsSelected--
            tagsActive.splice(tagsActive.indexOf(tags[tagId].tag), 1)
        }

        this.setState({tags: tags, tagsSelected, tagsActive}, () => {
            this.updateDisplayedCases()
        })
    }

    toggleCategory = (index) => {
        let categories = [...this.props.categories]
        categories[index].checked = !categories[index].checked
        this.props.updateCategories(categories, () => {
            this.updateDisplayedCases()
        })
    }

    updateDisplayedCases = (triggerAscending = null) => {
        let caseList = [...this.props.unfilteredCaseList]

        // Check categories
        let tempCaseList = []
        let categories = []

        this.props.categories.forEach((category, categoryIndex) => {
            if (category.checked) {
                categories.push(category.name)
            }
        })

        let sortDirection = this.props.sortDirection

        if (
            this.props.sortDirection === this.state.currentSortDirection &&
            this.state.currentSortDirection === 'Descending' &&
            triggerAscending === null
        ) {
            sortDirection = 'Ascending'
        }

        caseList.forEach((caseValue, caseIndex) => {
            if (categories.some((category) => category === caseValue.CASECATEGORYDESC))
                tempCaseList.push(caseValue)
        })

        caseList = tempCaseList

        tempCaseList = []

        // Check tags
        if (this.state.tagsActive.length !== 0) {
            let activeTags = [...this.state.tagsActive]
            let uniqueCaseIds = []

            // Need to verify cases are only being pushed once
            caseList.forEach((caseValue, caseIndex) => {
                let caseTags = caseValue.TAGS.split('~')

                if (activeTags.every((activeTag) => caseTags.some((caseTag) => activeTag === caseTag))) {
                    if (!uniqueCaseIds.some((caseId) => caseId === caseValue.ID)) {
                        tempCaseList.push(caseValue)
                        uniqueCaseIds.push(caseValue.ID)
                    }
                }
            })

            caseList = tempCaseList
            tempCaseList = []
        }

        // Check hide completed and hide incomplete
        if (this.props.hideCompleted) {
            caseList.forEach((caseValue) => {
                if (!caseValue.COMPLETED || caseValue.LastCompletionDate === "0001-01-01T00:00:00" )
                    tempCaseList.push(caseValue)
            })

            caseList = tempCaseList
            tempCaseList = []
        } else if (this.props.hideIncomplete) {
            caseList.forEach((caseValue) => {
                if (caseValue.COMPLETED && caseValue.LastCompletionDate !== "0001-01-01T00:00:00")
                    tempCaseList.push(caseValue)
            })

            caseList = tempCaseList
            tempCaseList = []
        }

        let completedCases = []
        let incompleteCases = []

        // Order by sort selection
        switch(this.props.sortOrder) {
            case 'Category':
                caseList = caseList.sort((a, b) => {
                    return a.CASECATEGORYDESC === b.CASECATEGORYDESC ? 0 : a.CASECATEGORYDESC < b.CASECATEGORYDESC ? -1 : 1;
                })
                break;
            case 'TimeLimit':
                caseList = caseList.sort((a, b) => {
                    return a.TIMEMODEDESC === b.TIMEMODEDESC ? 0 : a.TIMEMODEDESC < b.TIMEMODEDESC ? -1 : 1;
                })
                break;
            case 'Completed':
                // Split caseList into completed and incomplete
                completedCases = []
                incompleteCases = []

                caseList.forEach((caseValue, caseIndex) => {
                    if (caseValue.COMPLETED && caseValue.LastCompletionDate !== "0001-01-01T00:00:00")
                        completedCases.push(caseValue)
                    else
                        incompleteCases.push(caseValue)
                })

                // Sort completed cases by completion date
                completedCases = completedCases.sort((a, b) => {
                    return a.LastCompletionDate === b.LastCompletionDate ? 0 : a.LastCompletionDate > b.LastCompletionDate ? -1 : 1;
                })

                // Sort incomplete cases by case id
                incompleteCases = incompleteCases.sort((a, b) => {
                    return a.ID === b.ID ? 0 : a.ID < b.ID ? -1 : 1;
                })

                // Combine completed and incomplete cases
                caseList = [...completedCases, ...incompleteCases]

                break;
            case 'ReviewLater':
                caseList = caseList.sort((a, b) => {
                    return (a.ReviewLaterFlag === b.ReviewLaterFlag) ? 0 : a.ReviewLaterFlag ? -1 : 1
                })
                break;
            case 'HighYield':
                caseList = caseList.sort((a, b) => b.HIGHYIELDCOUNT - a.HIGHYIELDCOUNT)
                break;
            case 'AverageGrade':
                // Split caseList into completed and incomplete
                completedCases = []
                incompleteCases = []

                caseList.forEach((caseValue, caseIndex) => {
                    if (caseValue.COMPLETED && caseValue.LastCompletionDate !== "0001-01-01T00:00:00")
                        completedCases.push(caseValue)
                    else
                        incompleteCases.push(caseValue)
                })

                // Sort completed cases by completion date
                completedCases = completedCases.sort((a, b) => b.AvgGradeForCase - a.AvgGradeForCase)

                // Sort incomplete cases by case id
                incompleteCases = incompleteCases.sort((a, b) => {
                    return a.ID === b.ID ? 0 : a.ID < b.ID ? -1 : 1;
                })

                // Combine completed and incomplete cases
                caseList = [...completedCases, ...incompleteCases]

                break;
            case 'LastCompletionGrade':
                // Split caseList into completed and incomplete
                completedCases = []
                incompleteCases = []

                caseList.forEach((caseValue, caseIndex) => {
                    if (caseValue.COMPLETED && caseValue.LastCompletionDate !== "0001-01-01T00:00:00")
                        completedCases.push(caseValue)
                    else
                        incompleteCases.push(caseValue)
                })

                // Sort completed cases by completion date
                completedCases = completedCases.sort((a, b) => b.LastCaseAttemptGrade - a.LastCaseAttemptGrade)

                // Sort incomplete cases by case id
                incompleteCases = incompleteCases.sort((a, b) => {
                    return a.ID === b.ID ? 0 : a.ID < b.ID ? -1 : 1;
                })

                // Combine completed and incomplete cases
                caseList = [...completedCases, ...incompleteCases]

                break;
            case 'Diagnosis':
                caseList = caseList.sort((a, b) => {
                    return a.DIAGNOSIS === b.DIAGNOSIS ? 0 : a.DIAGNOSIS < b.DIAGNOSIS ? -1 : 1;
                })
                break;
            case 'Title':
                caseList = caseList.sort((a, b) => {
                    return a.TITLE === b.TITLE ? 0 : a.TITLE < b.TITLE ? -1 : 1;
                })
                break;
            default:
                break;
        }

        // Remove hidden cases from caseList
        let hiddenCases = [...this.props.hiddenCases]
        hiddenCases.forEach((hiddenCase) => {
            caseList = caseList.filter((caseValue) => {
                return caseValue.ID !== hiddenCase.id
            })
        })

        // Reverse case list order if needed
        if (sortDirection === 'Descending') {
            caseList.reverse()
        }

        // Insert headers into caseList
        switch(this.props.sortOrder) {
            case 'Category':
                if (caseList.length > 0)
                    caseList.splice(0, 0, {
                        type: 'icon',
                        text: caseList[0].CASECATEGORYDESC,
                        icon: this.getCategoryIcon(caseList[0].CASECATEGORYDESC)
                    })
                    
                for(let i = 1; i < caseList.length - 1; ++i) {
                    if (caseList[i].CASECATEGORYDESC !== caseList[i + 1].CASECATEGORYDESC) {
                        caseList.splice(i + 1, 0, {
                            type: 'icon',
                            text: caseList[i + 1].CASECATEGORYDESC,
                            icon: this.getCategoryIcon(caseList[i + 1].CASECATEGORYDESC)
                        })
                        i += 1
                    }
                }
                break;
            case 'TimeLimit':
                // Timer
                if (caseList.length > 0)
                    caseList.splice(0, 0, {
                        type: 'icon',
                        text: caseList[0].TIMEMODEDESC,
                        icon: faTimer
                    })

                for(let i = 1; i < caseList.length - 1; ++i) {
                    if (caseList[i].TIMEMODEDESC !== caseList[i + 1].TIMEMODEDESC) {
                        caseList.splice(i + 1, 0, {
                            type: 'icon',
                            text: caseList[i + 1].TIMEMODEDESC,
                            icon: faTimer
                        })
                        i += 1
                    }
                }
                break;
            case 'Id':
                // offset is used to change the default shown number of cases from 10 to 10 + offset
                let offset = this.props.caseListStyle ? 0 : 10
                for(let i = 0; i < caseList.length; ++i) {
                    if (i % (11 + offset) === 0 && i + (9 + offset) < caseList.length) {
                        caseList.splice(i, 0, {
                            type: 'logoText',
                            text: 'Case Number',
                            headerText: `${caseList[i].ID} - ${caseList[i + (9 + offset)].ID}`
                        })
                        // console.log(`${caseList[i].ID} - ${caseList[i + (9 + offset)].ID}`, i)
                        i+= 1
                    } else if (i % (11 + offset) === 0) {
                        // console.log(caseList[i].ID)
                        caseList.splice(i, 0, {
                            type: 'logoText',
                            text: 'Case Number',
                            headerText: `${caseList[i].ID} - ${caseList[caseList.length - 1].ID}`
                        })
                        i+= 1
                    }
                }
                break;
            case 'Completed':
                for(let i = 0; i < caseList.length - 1; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i === 0 && caseList[i].COMPLETED && caseList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Completed Cases",
                                icon: faCircleCheck
                            })
                            i += 1
                        } else if (i === 0 && (!caseList[i].COMPLETED || (caseList[i].COMPLETED && caseList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                        if ((caseList[i].COMPLETED !== caseList[i + 1].COMPLETED) || (caseList[i + 1].COMPLETED && caseList[i + 1].LastCompletionDate === "0001-01-01T00:00:00")) {
                            caseList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                    } else {
                        if (i === 0 && (!caseList[i].COMPLETED || (caseList[i].COMPLETED && caseList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            i += 1
                        } else if (i === 0 && caseList[i].COMPLETED && caseList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Completed Cases",
                                icon: faCircleCheck
                            })
                            break
                        }
                        if (caseList[i].COMPLETED && caseList[i + 1].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Completed Cases",
                                icon: faCircleCheck
                            })
                            break
                        }
                    }
                }
                break;
            case 'ReviewLater':
                if (sortDirection === 'Ascending') {
                    if (caseList.length > 0) {
                        caseList.splice(0, 0, {
                            type: 'icon',
                            text: "Review Later",
                            icon: faCircleCheck
                        })
                    }
                } else {
                    if (caseList.length > 0) {
                        caseList.splice(0, 0, {
                            type: 'icon',
                            text: "Unmarked Cases",
                            icon: faCircleXmark
                        })
                    }
                }

                for(let i = 1; i < caseList.length - 1; ++i) {
                    if (caseList[i].ReviewLaterFlag !== caseList[i + 1].ReviewLaterFlag) {
                        if (sortDirection === 'Ascending') {
                            caseList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Unmarked Cases",
                                icon: faCircleXmark
                            })
                        } else {
                            caseList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Review Later",
                                icon: faCircleCheck
                            })
                        }
                        break
                    }
                }
                break;
            case 'HighYield':
                if (sortDirection === 'Ascending') {
                    if (caseList.length > 0) {
                        caseList.splice(0, 0, {
                            type: 'logoText',
                            text: "High Yield",
                            headerText: `Top 1 - 10`
                        })
                    }
                } else {
                    if (caseList.length > 0) {
                        caseList.splice(0, 0, {
                            type: 'logoText',
                            text: "High Yield",
                            headerText: `Top ${caseList.length} - ${caseList.length - 9}`
                        })
                    }
                }

                let counter = 1

                for(let i = 1; i < caseList.length; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i % 11 === 0) {
                            let highNumber = i + 10 - counter
                            let lowNumber = i + 1 - counter

                            if (highNumber + counter > caseList.length - 1) {
                                highNumber = caseList.length - counter
                            }

                            caseList.splice(i, 0, {
                                type: 'logoText',
                                text: "High Yield",
                                // headerText: `Top ${((Math.ceil(i / 10) - 1) * 10) + 1} - ${Math.ceil(i / 10) * 10}`
                                headerText: `Top ${lowNumber} - ${highNumber}`
                            })
                            counter++
                        }
                    } else {
                        if (i % 11 === 0) {
                            let lowNumber = caseList.length - i - 9
                            let highNumber = caseList.length - i
                            if (caseList.length - i - 10 < 0) {
                                lowNumber = 1
                            }
                            caseList.splice(i, 0, {
                                type: 'logoText',
                                text: "High Yield",
                                headerText: `Top ${highNumber} - ${lowNumber}`
                            })
                        }
                    }
                }
                break;
            case 'AverageGrade':
                for(let i = 0; i < caseList.length - 1; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i === 0 && caseList[i].COMPLETED && caseList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            i += 1
                        } else if (i === 0 && (!caseList[i].COMPLETED || (caseList[i].COMPLETED && caseList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                        if ((caseList[i].COMPLETED !== caseList[i + 1].COMPLETED) || (caseList[i + 1].COMPLETED && caseList[i + 1].LastCompletionDate === "0001-01-01T00:00:00")) {
                            caseList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                    } else {
                        if (i === 0 && (!caseList[i].COMPLETED || (caseList[i].COMPLETED && caseList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            i += 1
                        } else if (i === 0 && caseList[i].COMPLETED && caseList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            break
                        }
                        if (caseList[i].COMPLETED && caseList[i + 1].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            break
                        }
                    }
                }
                break;
            case 'LastCompletionGrade':
                for(let i = 0; i < caseList.length - 1; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i === 0 && caseList[i].COMPLETED && caseList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Last Completion Grade",
                                icon: faCircleCheck
                            })
                            i += 1
                        } else if (i === 0 && (!caseList[i].COMPLETED || (caseList[i].COMPLETED && caseList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                        if ((caseList[i].COMPLETED !== caseList[i + 1].COMPLETED) || (caseList[i + 1].COMPLETED && caseList[i + 1].LastCompletionDate === "0001-01-01T00:00:00")) {
                            caseList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                    } else {
                        if (i === 0 && (!caseList[i].COMPLETED || (caseList[i].COMPLETED && caseList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            i += 1
                        } else if (i === 0 && caseList[i].COMPLETED && caseList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i, 0, {
                                type: 'icon',
                                text: "Last Completion Grade",
                                icon: faCircleCheck
                            })
                            break
                        }
                        if (caseList[i].COMPLETED && caseList[i + 1].LastCompletionDate !== "0001-01-01T00:00:00") {
                            caseList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            break
                        }
                    }
                }
                break;
            case 'Diagnosis':
                if (caseList.length > 0)
                    caseList.splice(0, 0, {
                        type: 'icon',
                        text: 'Diagnosis',
                        icon: faCommentMedical
                    })
                if (!this.props.showCaseDiagnosis) {
                    this.props.toggleShowCaseDiagnosis()
                }
                break;
            case 'Title':
                if (this.props.sortDirection === 'Ascending') {
                    if (caseList.length > 0) {
                        caseList.splice(0, 0, {
                            type: 'icon',
                            text: 'Title',
                            icon: faArrowUpAZ
                        })
                    }
                } else {
                    if (caseList.length > 0) {
                        caseList.splice(0, 0, {
                            type: 'icon',
                            text: 'Title',
                            icon: faArrowDownZA
                        })
                    }
                }
                if (!this.props.showCaseTitle) {
                    this.props.toggleShowCaseTitle()
                }
                break;
            default:
                break;
        }

        // Add caseList to state
        this.setState({
            filteredCaseList: caseList,
            currentSortDirection: sortDirection
        }, () => {
            if (this.props.sortDirection !== sortDirection) {
                this.props.toggleSortDirection(false)
            }
        })
    }

    toggleSort = (e) => {
        this.props.updateSortOrder(e.target.value, () => {
            this.updateDisplayedCases()
        })
    }

    toggleCaseListStyle = async (modernList) => {
        if (this.props.caseListStyle === modernList) {
            return
        }
        this.setState({caseListStyleToggleOutstanding: true})
        await this.props.toggleCaseListStyle()
        this.updateDisplayedCases()
        this.setState({caseListStyleToggleOutstanding: false})

        setTimeout(() => {
            if (this.props.selectedCase !== -1) {
                // scroll to selected case
                let selectedCase = document.getElementById(`case-${this.props.selectedCase}`)
                if (selectedCase !== null) {
                    selectedCase.scrollIntoView({block: 'center'})
                }
            }
        }, 750)
    }

    GetCaseListSidebar = () => {
        return (
            <div className='caseListSidebar' onClick={() => this.setState({displayCaseMoreOptionsPopup: false})}>
                <div className='caseListLogoContainer'>
                    <img className='caseListLogo' alt='CCS Cases Logo' src={Logo}/>
                    <button className='caseListDrawerCloseButton' onClick={() => this.setState({displayMobileDrawer: false})} />
                </div>
                {this.props.showSubscriptionEndWarning ?
					<p className='subscriptionEndWarningText'>Your subscription ends in {this.props.subscriptionDaysRemaining} {this.props.subscriptionDaysRemaining === 1 ? 'Day' : 'Days'}.</p>
				:
					null
				}
                <div className='caseListMobileNavButtonContainer'>
                    <button className='caseListMobileNavButton' onClick={() => this.handlePageChange('reportCard')}>
                        <FontAwesomeIcon icon={faFileChartPie}  className='caseListHeaderButtonIcon'/>
                        View Report Card
                    </button>
                    <button className='caseListMobileNavButton' onClick={() => this.handlePageChange('accountSettings')}>
                        <FontAwesomeIcon icon={faUserDoctor}  className='caseListHeaderButtonIcon'/>
                        My Account
                    </button>
                </div>
                <div className='caseListDropdownWrapper dropdownWithButton'>
                    <label className='standardLabel caseListSidebarLabel'>Sort By</label>
                    <select value={this.props.sortOrder} onChange={this.toggleSort} className='caseListDropdown'>
                        <option value="Id">Case Number</option>
                        <option value="Category">Category</option>
                        <option value="TimeLimit">Time Limit</option>
                        <option value="Completed">Completion Date</option>
                        <option value="ReviewLater">Review Later</option>
                        <option value="HighYield">High Yield</option>
                        {this.props.userProfile.ShowLastCaseAttempt ?
                            <option value="LastCompletionGrade">Last Completion Grade</option>
                        :
                            <option value="AverageGrade">Average Grade</option>
                        }
                        <option value="Diagnosis">Diagnosis</option>
                        <option value="Title">Case Title</option>
                    </select>
                    <button className='caseListSortDirectionButton' onClick={this.props.toggleSortDirection}>
                        <FontAwesomeIcon icon={this.props.sortDirection === 'Ascending' ? faArrowUpShortWide : faArrowDownWideShort} className='caseListSortDirectionIcon' />
                    </button>
                </div>
                <h2 className='caseListSidebarHeader'>Categories</h2>
                {this.props.categories.map((category, index) => (
                    <div className='caseListSidebarCategoryWrapper' onClick={() => this.toggleCategory(index)} key={index + ' - ' + category.name}>
                        <div className='checkboxContainer'>
                            {category.checked && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                        </div>
                        <FontAwesomeIcon icon={category.icon} className='caseListSidebarCategoryIcon' />
                        <p className="caseListSidebarText">{category.name}</p>
                    </div>
                ))}
                <div className="caseListSidebarSectionBreak" />
                <h2 className='caseListSidebarHeader' style={{marginBottom: '5px'}}>Tags</h2>
                <p className='caseListSidebarSubtext'>Tags help you narrow down your search</p>
                {this.state.tags.map((tag, index) => (
                    tag.selected &&
                    <div className='caseListSidebarTagWrapper' key={tag.tag + ' - ' + index} onClick={() => this.toggleTag(index)}>
                        <div className='checkboxContainer'>
                            {tag.active && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                        </div>
                        <p className='caseListSidebarText'>{tag.tag}</p>
                    </div>
                ))}
                <button className='caseListSidebarAddTagButton secondaryButton' onClick={() => this.setState({displayTagsPopup: true})}>Add Tags</button>
                <div className="caseListSidebarSectionBreak" />
                <h2 className='caseListSidebarHeader'>Other</h2>
                {/* <div className='caseListSidebarTagWrapper' onClick={this.props.toggleShowCaseDiagnosis}> */}
                <div className={`caseListSidebarTagWrapper ${this.props.sortOrder === 'Diagnosis' ? 'sidebarToggleDisabled' : ''}`} onClick={this.props.toggleShowCaseDiagnosis}>
                    <div className='checkboxContainer'>
                        {this.props.showCaseDiagnosis && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                    </div>
                    <p className='caseListSidebarText'>Show Case Diagnosis</p>
                </div>
                {/* <div className='caseListSidebarTagWrapper' onClick={this.props.toggleShowCaseTitle}> */}
                <div className={`caseListSidebarTagWrapper ${this.props.sortOrder === 'Title' ? 'sidebarToggleDisabled' : ''}`} onClick={this.props.toggleShowCaseTitle}>
                    <div className='checkboxContainer'>
                        {/* {!this.props.showCaseTitle && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />} */}
                        {!this.props.showCaseTitle && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                    </div>
                    <p className='caseListSidebarText'>Hide Case Title</p>
                </div>
                <div className='caseListSidebarTagWrapper' onClick={this.props.toggleHideCompleted}>
                    {/* <input type="radio" /> */}
                    <div className='checkboxContainer'>
                        {this.props.hideCompleted && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                    </div>
                    <p className='caseListSidebarText'>Hide Completed</p>
                </div>
                <div className='caseListSidebarTagWrapper' onClick={this.props.toggleHideIncomplete}>
                    {/* <input type="radio" /> */}
                    <div className='checkboxContainer'>
                        {this.props.hideIncomplete && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                    </div>
                    <p className='caseListSidebarText'>Hide Incomplete</p>
                </div>
                {this.state.pageWidth >= 600 &&
                    <div className='caseListDropdownWrapper' style={{marginTop: '10px'}}>
                        <label className='standardLabel caseListSidebarLabel'>Case List Style</label>
                        <div className='caseListStyleToggle'>
                            <button className={`caseListStyleToggleOption ${this.props.caseListStyle ? 'caseListStyleOptionSelected' : ''}`} onClick={() => this.toggleCaseListStyle(true)}>Card</button>
                            <button className={`caseListStyleToggleOption ${this.props.caseListStyle ? '' : 'caseListStyleOptionSelected'}`} onClick={() => this.toggleCaseListStyle(false)}>List</button>
                            {this.state.caseListStyleToggleOutstanding &&
                                <div className='caseListStyleToggleLoadingContainer'>
                                    <MDSpinner 
                                        size={30}
                                        singleColor={this.props.secondaryColor}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                }
                <h2 className='caseListSidebarHeader' style={{marginBottom: '5px', marginTop: '25px'}}>Exam Settings</h2>
                <div className='caseListSidebarTagWrapper' onClick={this.props.timedExamToggle}>
                    {/* <input type="radio" /> */}
                    <div className='checkboxContainer'>
                        {this.props.timedExam && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                    </div>
                    <p className='caseListSidebarText'>Timed Exam</p>
                </div>
                <div className='caseListDropdownWrapper' style={{marginTop: '10px'}}>
                    <label className='standardLabel caseListSidebarLabel'>Custom Time Limit</label>
                    <select value={this.props.customTimeLimit} onChange={this.props.customTimeLimitChange} className='caseListDropdown'>
                        <option value='none'>None</option>
                        <option value='8'>8 mins</option>
                        <option value='9'>9 mins</option>
                        <option value='10'>10 mins</option>
                        <option value='15'>15 mins</option>
                        <option value='18'>18 mins</option>
                        <option value='19'>19 mins</option>
                        <option value='20'>20 mins</option>
                        <option value='25'>25 mins</option>
                        <option value='30'>30 mins</option>
                        <option value='40'>40 mins</option>
                    </select>
                </div>
                <div className='caseListDropdownWrapper' style={{marginTop: '10px'}}>
                    <label className='standardLabel caseListSidebarLabel'>Simulate Network Lag</label>
                    <select value={this.props.networkLag} onChange={this.props.networkLagChange} className='caseListDropdown'>
                        <option value='none'>None</option>
                        <option value='1'>1 second</option>
                        <option value='2'>2 seconds</option>
                        <option value='3'>3 seconds</option>
                        <option value='4'>4 seconds</option>
                        <option value='5'>5 seconds</option>
                        <option value='6'>6 seconds</option>
                        <option value='random'>Random</option>
                    </select>
                </div>
            </div>
        )
    }

    confirmHideCase = async (selectedCase, togglePopup) => {

        let body = JSON.stringify({
            CaseNumberToHide: selectedCase.ID,
            CustomerId: this.props.userData.CustomerId,
            RemoveCase: false
        })
        this.props.setPopupLoading(true)
        if (togglePopup && !this.props.hideCasePopup) {
            let hideCasePopupFetch = await fetch(`${this.props.route}/togglehidecasepopup.webapi`, {
                method: 'POST',
                headers: {
                    'Token': this.props.userData.Token,
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify({
                    ToggleDontShowHideCasePopup: true,
                    CustomerId: this.props.userData.CustomerId
                })
            })

            if (!hideCasePopupFetch.ok) {
                this.props.setPopupLoading(false)
                window.alert('Error: Unable to toggle hide case popup')
                return
            }

            let hideCasePopupText = await hideCasePopupFetch.text()
        }
        await fetch(`${this.props.route}/addremovehiddencases.webapi`, {
            method: 'POST',
            headers: {
                'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
            },
            body
        })
        .then((response) => {
            if (response.ok) {
                return response.text()
            } else if (response.status === 401) {
                this.setAuthenticationError()
            } else {
                console.log(response)
                throw 'An error occured while attempting to hide the case. Please try again.'
            }
        })
        .then((response) => {
            this.props.setPopupLoading(false)
            let hiddenCases = this.props.hiddenCases
            hiddenCases.push({
                id: selectedCase.ID,
                category: this.props.getCaseCategoryFromId(selectedCase.ID)
            })
            this.props.updateHiddenCases(hiddenCases)
            this.updateDisplayedCases()
        })
        .catch((error) => {
            this.props.setPopupLoading(false)
            window.alert(error)
        })
    }

    hideCaseHandler = async (e, caseId) => {
        e.stopPropagation()
        let caseList = [...this.state.filteredCaseList]
        let selectedCase = caseList.find((caseItem) => caseItem.ID === caseId)

        if (this.props.hideCasePopup) {
            let children = (
                <div className='confirmationPopupCheckboxWrapper' onClick={this.props.hideCasePopupCheckboxHandler}>
                    <div className='checkboxContainer' key={this.props.hideCasePopup}>
                        {!this.props.hideCasePopup && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                    </div>
                    <p className='confirmationPopupCheckboxLabel'>Don't show this again</p>
                </div>
            )
    
            let confirmationPopupOptions = {
                title: `Hide Case ${selectedCase.ID}?`,
                description: `Are you sure you want to hide case ${selectedCase.ID} - ${selectedCase.TITLE}? You can unhide this case in your account settings under "Manage Hidden Cases".`,
                confirmFunction: async () => {
                    await this.confirmHideCase(selectedCase, true)
                    this.props.closeConfirmationPopup()
                },
                children,
                closePopup: () => {
                    this.props.toggleHideCasePopup(true)
                    this.props.closeConfirmationPopup()
                }
            }
            this.props.displayConfirmationPopup(confirmationPopupOptions)
        } else {
            this.setState({hideCaseLoadingIdArray: [...this.state.hideCaseLoadingIdArray, selectedCase.ID]})
            await this.confirmHideCase(selectedCase, false)
            this.setState({hideCaseLoadingIdArray: this.state.hideCaseLoadingIdArray.filter((i) => i !== selectedCase.ID)})
        }
    }

    displayMoreOptionsHandler = (event, caseId, index) => {
        event.stopPropagation()
        if (!this.state.displayCaseMoreOptionsPopup || this.props.selectedCase !== caseId) {
            let x = event.clientX
            let y = event.clientY
            this.setState({
                moreOptionsPopupPosition: {
                    x,
                    y,
                    selectedCase: this.props.selectedCase,
                    selectedCaseIndex: index,
                },
                displayCaseMoreOptionsPopup: true,
            }, () => {
                this.props.setSelectedCase(caseId)
            })
        } else {
            this.setState({displayCaseMoreOptionsPopup: false}, () => {
                this.props.setSelectedCase(caseId)
            })
        }
    }

    selectCaseRowHandler = (e, value) => {
        e.stopPropagation()
        this.setState({displayCaseMoreOptionsPopup: false})
        this.props.setSelectedCase(value.ID)
    }

    getRowColumns = () => {
        if (this.props.showCaseDiagnosis && this.props.showCaseTitle && !this.props.userProfile.hideHighYield) {
            return `50px 60px 2fr 2fr 1fr 1fr 1fr 1fr 120px 30px`
        } else if ((this.props.showCaseDiagnosis || this.props.showCaseTitle) && (!this.props.showCaseDiagnosis || !this.props.showCaseTitle) && this.props.userProfile.hideHighYield) {
            return '50px 60px 3fr 1fr 1fr 1fr 120px 30px'
        } else if (this.props.showCaseDiagnosis && this.props.showCaseTitle && this.props.userProfile.hideHighYield) {
            return `50px 60px 2fr 2fr 1fr 1fr 1fr 120px 30px`
        } else {
            return '50px 60px 3fr 1fr 1fr 1fr 1fr 120px 30px'
        }
    }

    getMoreOptionsTopOffset = () => {
        let buttonElement = document.getElementById('moreOptionsPopupContainer')
        if (!buttonElement) {
            return 0
        }
        let elementHeight = parseInt(window.getComputedStyle(buttonElement, null).getPropertyValue('height')) + 20
        if (this.state.moreOptionsPopupPosition.y + elementHeight > window.innerHeight) {
            return window.innerHeight - elementHeight
        } else {
            return this.state.moreOptionsPopupPosition.y
        }
    }

    toggleColumnTitleSort = (sortOrder) => {
        if (this.props.sortOrder === sortOrder) {
            this.props.toggleSortDirection()
        }
        this.props.updateSortOrder(sortOrder, () => {
            this.updateDisplayedCases()
        })
    }

    render() {
        return (
            <div className='caseListPageContainer' style={{height: `${this.state.pageHeight}px`}}>
                <div className='caseListSidebarWrapper'>
                    <this.GetCaseListSidebar />
                </div>
                <div className="caseListBodyPageWrapper" onClick={() => this.setState({displayCaseMoreOptionsPopup: false})} style={{height: `${this.state.pageHeight}px`}}>
                    <div className='caseListHeaderButtonContainer' style={{width: this.props.windowsSystem ? 'calc(100% - 36px)' : 'calc(100% - 20px'}}>
                        <button className='caseListHeaderButton secondaryButton' onClick={() => this.handlePageChange('reportCard')}>
                            <FontAwesomeIcon icon={faFileChartPie} className='caseListHeaderButtonIcon' />
                            Report Card
                        </button>
                        <button className='caseListHeaderButton secondaryButton' onClick={() => this.handlePageChange('accountSettings')}>
                            <FontAwesomeIcon icon={faUserDoctor} className='caseListHeaderButtonIcon' />
                            My Account
                        </button>
                        <button className='caseListHeaderMobileButton secondaryButton' onClick={() => this.handlePageChange('reportCard')}>
                            <FontAwesomeIcon icon={faFileChartPie} className='caseListHeaderButtonIcon' />
                        </button>
                        <button className='caseListHeaderMobileButton secondaryButton' onClick={() => this.handlePageChange('accountSettings')}>
                            <FontAwesomeIcon icon={faUserDoctor} className='caseListHeaderButtonIcon' />
                        </button>
                        <button className='caseListHeaderBurgerButton secondaryButton' onClick={() => this.setState({displayMobileDrawer: true})}>
                            <FontAwesomeIcon icon={faBars} className='caseListHeaderButtonIcon' />
                        </button>
                    </div>
                    <AnimatePresence exitBeforeEnter>
                        {!this.props.loadingCaseList ?
                            // true ? <motion.div
                            (this.props.caseListStyle || this.state.pageWidth < 600) ? <motion.div
                                className='caseListContainer'
                                initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                                exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                style={{height: `${this.state.pageHeight - 60}px`}}
                                key="CaseListCardContainer"
                            >
                                {this.state.filteredCaseList.map((value, index) => (
                                    value.type ?
                                        <div className='caseListSectionHeaderContainer' key={`${value.headerText} - ${index}`}>
                                            {value.type === 'logoText' ?
                                                <p className='castListSectionLogoText'>{value.headerText}</p>
                                            :
                                                <FontAwesomeIcon icon={value.icon} className='caseListSectionIcon' />
                                            }
                                            <h1 className='caseListSectionHeader'>{value.text}</h1>
                                        </div>
                                    :
                                        // Changed scale(0.95) to scale(1) to fix case list crashing on mobile due to what seems to be framer motion rendering
                                        // issues on iOS 16
                                        <motion.div
                                            initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(1)`}}
                                            animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                                            exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(1)`}}
                                            id={`case-${value.ID}`}
                                            transition={{ delay: index < 15 ? index / 80 : 0.2 }}
                                            className={`caseListCaseContainer${this.props.selectedCase === value.ID ? ' caseListCaseSelected' : ''}`}
                                            onClick={() => this.props.setSelectedCase(value.ID)} key={value.TITLE + ' - ' + value.ID}
                                        >
                                            <div className='caseListCaseHeaderWrapper'>
                                                <h2 className={`caseListCaseId${this.props.selectedCase === value.ID ? ' caseListWhite' : ''}`}>{value.ID}</h2>
                                                <h3 className='caseListCaseTitle'>{this.props.showCaseDiagnosis ? value.DIAGNOSIS : !this.props.showCaseTitle ? value.CASECATEGORYDESC : value.TITLE}</h3>
                                            </div>
                                            {this.props.userProfile.hideHighYield ?
                                                <div className='caseListMetaDetailsWrapper'>
                                                    <FontAwesomeIcon icon={this.getCategoryIcon(value.CASECATEGORYDESC)} className={`caseListCaseIcon${this.props.selectedCase === value.ID ? ' caseListWhite' : ''}`} />
                                                    <p className='caseListMetaText'>{this.props.showCaseTitle || this.props.showCaseDiagnosis ? `${value.CASECATEGORYDESC} | ` : ''}{value.TIMEMODEDESC}</p>
                                                </div>
                                            :
                                                <div className='caseListMetaDetailsWrapper'>
                                                    <FontAwesomeIcon icon={this.getCategoryIcon(value.CASECATEGORYDESC)} className={`caseListCaseIcon${this.props.selectedCase === value.ID ? ' caseListWhite' : ''}`} />
                                                    <p className='caseListMetaText'>{this.props.showCaseTitle || this.props.showCaseDiagnosis ? `${value.CASECATEGORYDESC} | ` : ''}{value.TIMEMODEDESC} | <strong>{value.HIGHYIELDCOUNT} High Yield</strong></p>
                                                </div>
                                            }
                                            <p className='caseListCaseDiagnosisText'>{(this.props.showCaseDiagnosis && this.props.showCaseTitle) ? value.TITLE : ''}</p>
                                            <div className={`caseListCaseFooterContainer${this.props.selectedCase === value.ID ? ' caseListCaseFooterSelected' : ''}`}>
                                                <div className='caseListCaseFooterReviewLaterContainer'>
                                                    {this.state.reviewLaterFetchesOutstanding.includes(value.ID) ?
                                                        <MDSpinner
                                                            size={20}
                                                            singleColor={this.props.selectedCase === value.ID ? '#fff' : this.props.primaryColor}
                                                        />
                                                    :
                                                        <div className='checkboxContainer' onClick={(e) => this.fetchReviewLater(e, value)}>
                                                            {value.ReviewLaterFlag && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                                                        </div>
                                                    }
                                                    <p className={`caseListCaseReviewLaterText ${this.props.selectedCase === value.ID ? 'caseListCaseReviewLaterTextSelected' : ''}`}>Review Later</p>
                                                </div>
                                                {this.props.enableAnswerPeeking ?
                                                    value.COMPLETED && value.LastCompletionDate !== '0001-01-01T00:00:00' ?
                                                        <button className="caseListCaseButton secondaryButton" onClick={(e) => this.viewCaseGradesClick(e, value.ID)}>View Grades</button>
                                                    :
                                                        <button className="caseListCaseButton warningButton" onClick={(e) => this.peekCaseGrades(e, value.ID)}>Peek at Answers</button>
                                                : value.COMPLETED && value.LastCompletionDate !== '0001-01-01T00:00:00' &&
                                                    <button className="caseListCaseButton secondaryButton" onClick={(e) => this.viewCaseGradesClick(e, value.ID)}>View Grades</button>
                                                }
                                                { this.state.hideCaseLoadingIdArray.some((Id) => value.ID === Id) ?
                                                    <div className='caseListHideCaseSpinnerContainer'>
                                                        <MDSpinner size={20} singleColor={this.props.selectedCase === value.ID ? '#fff' : this.props.primaryColor} />
                                                    </div>
                                                :
                                                    <button className='caseListHideCaseButton secondaryButton' onClick={(e) => this.hideCaseHandler(e, value.ID)}>
                                                        <FontAwesomeIcon icon={faEyeSlash} className='caseListHideCaseButtonIcon' />
                                                    </button>
                                                }
                                            </div>
                                            { value.COMPLETED && value.LastCompletionDate !== '0001-01-01T00:00:00' &&
                                                <div className='caseListCaseLastCompletedDateContainer'>
                                                    <p className='caseListCaseLastCompletedDateText'>Last Completed: {this.formatCompletionDate(value.LastCompletionDate)}</p>
                                                </div>
                                            }
                                            {
                                                value.COMPLETED && value.LastCompletionDate !== '0001-01-01T00:00:00' &&
                                                <div className={`caseListCaseAverageGradeContainer${this.props.selectedCase === value.ID ? ' caseListGradeContainerSelected' : ''}`}>
                                                    {this.props.userProfile.ShowLastCaseAttempt ?
                                                        <p className='caseListCaseAverageGradeText'>Last Completion Grade: {value.LastCaseAttemptGrade.toFixed(2)}%</p>
                                                    :
                                                        <p className='caseListCaseAverageGradeText'>Average Grade: {value.AvgGradeForCase.toFixed(2)}%</p>
                                                    }
                                                </div>
                                            }
                                        </motion.div>
                                    ))
                                }
                                {this.state.filteredCaseList.length === 0 && !this.props.loadingCaseList &&
                                    <div className='caseListNoCasesContainer'>
                                        <h3 className='caseListCaseTitle caseListNoCasesTitle'>No Cases Match Your Search</h3>
                                        <p className='caseListCaseDiagnosisText caseListNoCasesText'>It looks like we don't have any cases matching your search. Try expanding your search or checking that you haven't hidden the case you're looking for.</p>
                                        <button className='caseListViewHiddenCasesButton tertiaryButton' onClick={this.props.viewHiddenCases}>View Hidden Cases</button>
                                    </div>
                                }
                            </motion.div>
                            :
                            <motion.div
                                className='caseListRowStyleListContainer'
                                initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                                exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                style={{height: `${this.state.pageHeight - 60}px`}}
                                key='CaseListListContainer'
                            >
                                {this.state.filteredCaseList.map((value, index) => (
                                    value.type ?
                                        <div className='caseListRowSectionHeaderContainer' key={`${value.headerText} - ${index}`}>
                                            <div className='caseListRowHeaderContainer'>
                                                {value.type === 'logoText' ?
                                                    <p className='castListSectionLogoText'>{value.headerText}</p>
                                                :
                                                    <FontAwesomeIcon icon={value.icon} className='caseListSectionIcon' />
                                                }
                                                <h1 className='caseListSectionHeader'>{value.text}</h1>
                                            </div>
                                            <div
                                                className='caseListRowLabelContainer'
                                                style={{gridTemplateColumns: this.getRowColumns()}}
                                            >
                                                <div />
                                                <h3 className='caseListRowLabel' style={{textAlign: 'center', justifySelf: 'center'}} onClick={() => this.toggleColumnTitleSort('Id')}>Case Number</h3>
                                                {this.props.showCaseTitle && <h3 className='caseListRowLabel' onClick={() => this.toggleColumnTitleSort('Title')}>Title</h3>}
                                                {this.props.showCaseDiagnosis && <h3 className='caseListRowLabel' onClick={() => this.toggleColumnTitleSort('Diagnosis')}>Diagnosis</h3>}
                                                {!this.props.showCaseTitle && !this.props.showCaseDiagnosis && <h3 className='caseListRowLabel' onClick={() => this.toggleColumnTitleSort('Category')}>Category</h3>}
                                                <h3 className='caseListRowLabel' style={{textAlign: 'center', justifySelf: 'center'}} onClick={() => this.toggleColumnTitleSort('Completed')}>Completion Date</h3>
                                                {!this.props.userProfile.hideHighYield && <h3 className='caseListRowLabel' style={{textAlign: 'center', justifySelf: 'center'}} onClick={() => this.toggleColumnTitleSort('HighYield')}>High Yield</h3>}
                                                <h3 className='caseListRowLabel' style={{textAlign: 'center', justifySelf: 'center'}} onClick={() => this.toggleColumnTitleSort('TimeLimit')}>Time Limit</h3>
                                                <h3 className='caseListRowLabel' style={{textAlign: 'center', justifySelf: 'center'}} onClick={() => this.toggleColumnTitleSort('ReviewLater')}>Review Later</h3>
                                                {this.props.userProfile.ShowLastCaseAttempt ?
                                                    <h3 className='caseListRowLabel' style={{textAlign: 'center', justifySelf: 'center'}} onClick={() => this.toggleColumnTitleSort('LastCompletionGrade')}>Last Completion Grade</h3>
                                                :
                                                    <h3 className='caseListRowLabel' style={{textAlign: 'center', justifySelf: 'center'}} onClick={() => this.toggleColumnTitleSort('AverageGrade')}>Average Grade</h3>
                                                }
                                                <div />
                                            </div>
                                        </div>
                                    :
                                        <motion.div
                                            className={`caseListRowContainer${this.props.selectedCase === value.ID ? ' caseListCaseSelected' : ''}`}
                                            initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                            animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                                            exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                            id={`case-${value.ID}`}
                                            style={{gridTemplateColumns: this.getRowColumns()}}
                                            transition={{ delay: index < 15 ? index / 80 : 0.2 }}
                                            onClick={(e) => this.selectCaseRowHandler(e, value)} key={value.TITLE + ' - ' + value.ID}
                                        >
                                            <div className='caseListRowIconContainer'>
                                                <FontAwesomeIcon icon={this.getCategoryIcon(value.CASECATEGORYDESC)} className='caseListRowIcon' />
                                            </div>
                                            <h4 className='caseListRowCaseNumber'>{value.ID}</h4>
                                            {this.props.showCaseTitle && <p className='caseListRowValue'>{value.TITLE}</p>}
                                            {this.props.showCaseDiagnosis && <p className='caseListRowValue'>{value.DIAGNOSIS}</p>}
                                            {!this.props.showCaseTitle && !this.props.showCaseDiagnosis && <p className='caseListRowValue'>{value.CASECATEGORYDESC}</p>}
                                            <p className='caseListRowValue' style={{textAlign: 'center', justifySelf: 'center'}}>{value.LastCompletionDate === '0001-01-01T00:00:00' ? 'N/A' : this.formatCompletionDate(value.LastCompletionDate)}</p>
                                            {!this.props.userProfile.hideHighYield && <p className='caseListRowValue' style={{textAlign: 'center', justifySelf: 'center'}}><strong>{value.HIGHYIELDCOUNT}</strong></p>}
                                            {<p className='caseListRowValue' style={{textAlign: 'center', justifySelf: 'center'}}>{value.TIMEMODEDESC}</p>}
                                            {this.state.reviewLaterFetchesOutstanding.includes(value.ID) ?
                                                <MDSpinner
                                                    size={20}
                                                    style={{justifySelf: 'center', alignSelf: 'center'}}
                                                    singleColor={this.props.primaryColor}
                                                />
                                            :
                                                <div className='checkboxContainer' onClick={(e) => this.fetchReviewLater(e, value)}>
                                                    {value.ReviewLaterFlag && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                                                </div>
                                            }
                                            {this.props.userProfile.ShowLastCaseAttempt ?
                                                <p className='caseListRowValue' style={{textAlign: 'center', justifySelf: 'center'}}>{value.LastCaseAttemptGrade.toFixed(2)}%</p>
                                            :
                                                <p className='caseListRowValue' style={{textAlign: 'center', justifySelf: 'center'}}>{value.AvgGradeForCase.toFixed(2)}%</p>
                                            }
                                            <button className='caseListRowMoreOptionsButton' onClick={(e) => this.displayMoreOptionsHandler(e, value.ID, index)}>
                                                <FontAwesomeIcon icon={faEllipsisV} className='caseListRowMoreOptionsIcon' />
                                            </button>
                                        </motion.div>
                                ))}
                                {this.state.filteredCaseList.length === 0 && !this.props.loadingCaseList &&
                                    <div className='caseListNoCasesContainer'>
                                        <h3 className='caseListCaseTitle caseListNoCasesTitle'>No Cases Match Your Search</h3>
                                        <p className='caseListCaseDiagnosisText caseListNoCasesText'>It looks like we don't have any cases matching your search. Try expanding your search or checking that you haven't hidden the case you're looking for.</p>
                                        <button className='caseListViewHiddenCasesButton tertiaryButton' onClick={this.props.viewHiddenCases}>View Hidden Cases</button>
                                    </div>
                                }
                            </motion.div>
                        :
                            <motion.div
                                className='caseListLoadingContainer'
                                initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                                exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                style={{height: `${this.state.pageHeight - 50}px`}}
                            >
                                <div className='caseListLoadingWrapper'>
                                    <MDSpinner size={50} singleColor={this.props.primaryColor}/>
                                    <p className='caseListLoadingText'>Loading Case List</p>
                                </div>
                            </motion.div>
                        }
                    </AnimatePresence>
                    <div className='caseListFooterContainer'>
                        <button className={`caseListFooterButton secondaryButton${this.state.loadingStartCase ? ' buttonDisabled' : ''}`} onClick={this.selectRandomCase}>Select Random Case</button>
                        {!this.state.loadingStartCase ?
                            <button className={`caseListFooterButton primaryButton${this.props.selectedCase === -1 ? ' buttonDisabled' : ''}`} onClick={() => this.beginCaseHandler(this.props.selectedCase)} >Start Case</button>
                        :
                            <div className='caseListFooterLoaderWrapper'>
                                <MDSpinner size={25} singleColor={this.props.primaryColor}/>
                            </div>
                        }
                    </div>
                </div>
                <AnimatePresence exitBeforeEnter>
                    {this.state.displayIncompleteCasesPopup &&
                        <IncompleteCasePopup
                            startNewCaseFunction={this.state.startNewCaseFunction}
                            incompleteCaseList={this.state.incompleteCaseList}
                            caseInfo={this.props.unfilteredCaseList[this.props.selectedCase - 1]}
                            closePopup={() => this.setState({displayIncompleteCasesPopup: false})}
                            getCategoryIcon={this.getCategoryIcon}
                            confirmIncompleteCaseStart={this.props.confirmIncompleteCaseStart}
                            key="IncompleteCasePopup"
                            showCaseTitle={this.props.showCaseTitle}
                            animationsDisabled={this.props.userProfile.animationsDisabled}
                            startNewCaseLoading={this.props.loadingPopupStartCase}
                            continueCaseLoading={this.props.continueCaseLoading}
                            primaryColor={this.props.primaryColor}
                            hideHighYield={this.props.userProfile.hideHighYield}
                            showCaseDiagnosis={this.props.showCaseDiagnosis}
                            TimeZone={this.props.userProfile.selectedTimeZone}
                        />
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {/* Popup for row style list case more options button */}
                    {this.state.displayCaseMoreOptionsPopup &&
                        <motion.div
                            className='moreOptionsPopupContainer'
                            initial={this.props.animationsDisabled ? false : moreOptionsPopupTransition.initial}
                            animate={this.props.animationsDisabled ? false : moreOptionsPopupTransition.animate}
                            exit={this.props.animationsDisabled ? false : moreOptionsPopupTransition.exit}
                            id="moreOptionsPopupContainer"
                            style={{
                                top: `${this.getMoreOptionsTopOffset()}px`,
                                left: `${this.state.moreOptionsPopupPosition.x - 220}px`
                            }}
                            transition={{ type: 'none', duration: 0.2}}
                            key={`moreOptionsPopupContainer - ${this.state.moreOptionsPopupPosition.selectedCase}`}
                        >
                            <button className='moreOptionPopupButton' onClick={(e) => this.setState({displayCaseMoreOptionsPopup: false}, () => this.hideCaseHandler(e, this.props.selectedCase))}>Hide Case</button>
                            {this.props.enableAnswerPeeking ?
                                this.state.filteredCaseList[this.state.moreOptionsPopupPosition.selectedCaseIndex].COMPLETED && this.state.filteredCaseList[this.state.moreOptionsPopupPosition.selectedCaseIndex].LastCompletionDate !== '0001-01-01T00:00:00' ?
                                    <button className="moreOptionPopupButton" onClick={(e) => this.viewCaseGradesClick(e, this.state.filteredCaseList[this.state.moreOptionsPopupPosition.selectedCaseIndex].ID)}>View Grades</button>
                                :
                                    <button className="moreOptionPopupButton warningButton" onClick={(e) => this.peekCaseGrades(e, this.state.filteredCaseList[this.state.moreOptionsPopupPosition.selectedCaseIndex].ID)}>Peek at Answers</button>
                            : this.state.filteredCaseList[this.state.moreOptionsPopupPosition.selectedCaseIndex].COMPLETED && this.state.filteredCaseList[this.state.moreOptionsPopupPosition.selectedCaseIndex].LastCompletionDate !== '0001-01-01T00:00:00' &&
                                <button className="moreOptionPopupButton" onClick={(e) => this.viewCaseGradesClick(e, this.state.filteredCaseList[this.state.moreOptionsPopupPosition.selectedCaseIndex].ID)}>View Grades</button>
                            }
                            {/* <button className='moreOptionPopupButton' onClick={(e) => this.viewCaseGradesClick(e, this.props.selectedCase)}>View Case Grades</button> */}
                        </motion.div>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {this.state.displayMobileDrawer &&
                        <div className='caseListMobileDrawerPageContainer' key="caseListMobileDrawer">
                            <motion.div
                                className='caseListMobileDrawerBackdrop'
                                key='caseListMobileNavBackdrop'
                                initial={this.props.animationsDisabled ? false : {opacity: 0}}
                                animate={this.props.animationsDisabled ? false : {opacity: 1}}
                                exit={this.props.animationsDisabled ? false : {opacity: 0}}
                                transition={{duration: 0.1}}
                                onClick={() => this.setState({displayMobileDrawer: false})}
                            />
                            <motion.div
                                className='caseListMobileDrawerContainer'
                                key='caseListMobileDrawerContainer'
                                initial={this.props.animationsDisabled ? false : {y: '90vh'}}
                                animate={this.props.animationsDisabled ? false : {y: 0}}
                                exit={this.props.animationsDisabled ? false : {y: '90vh'}}
                                transition={{ type: 'none', duration: 0.25}}
                            >
                                <this.GetCaseListSidebar />
                            </motion.div>
                        </div>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {this.state.displayTagsPopup &&
                        <TagsPopup
                            tags={this.state.tags}
                            selectTag={this.selectTag}
                            key="TagsPopup"
                            closePopup={() => this.setState({displayTagsPopup: false})}
                            tagsSelected={this.state.tagsSelected}
                            animationsDisabled={this.props.userProfile.animationsDisabled}
                        />
                    }
                </AnimatePresence>
            </div>
        )
    }
}
