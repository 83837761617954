import getDaysFromDate from '../GetDaysFromDate'

//formatCurrentOrders accepts the currentOrders array as an argument and loops through it
//We first check if the ID is undefined or zero and the length of currentOrders is 1. This indicates there are no current orders

//If the check fails, we loop through currentOrders to format their orderTime and ID and return the array
const formatCurrentOrders = (currentOrders) => {
    if (currentOrders) {
        if (currentOrders.length > 0) {
            if ((currentOrders[0].ID === undefined || currentOrders[0].ID === 0) && currentOrders.length === 1) {
                currentOrders = []
            } else {
                if (currentOrders[0].ID === undefined || currentOrders[0].ID === 0) {
                    currentOrders = currentOrders.slice(1, currentOrders.length)
                }
                for (let i = 0; i < currentOrders.length; ++i) {
                    let reportTime = new Date(`${currentOrders[i].ReportTime}Z`)
                    if (currentOrders[i].ReportTime === "0001-01-01T00:00:00") {
                        currentOrders[i].ReportTime = null
                    } else {
                        currentOrders[i].ReportTime = {
                            days: getDaysFromDate(reportTime),
                            hours: reportTime.getUTCHours(),
                            minutes: reportTime.getUTCMinutes()
                        }
                    }
                    let currentVirtualTime = new Date(`${currentOrders[i].CurrentVirtualTime}Z`)
                    currentOrders[i].CurrentVirtualTime = {
                        days: getDaysFromDate(currentVirtualTime),
                        hours: currentVirtualTime.getUTCHours(),
                        minutes: currentVirtualTime.getUTCMinutes()
                    }
                    currentOrders[i].orderId = currentOrders[i].ID
                    if (currentOrders[i].ReportTime) {
                        if (currentOrders[i].CurrentVirtualTime.days === currentOrders[i].ReportTime.days && currentOrders[i].CurrentVirtualTime.hours === currentOrders[i].ReportTime.hours && currentOrders[i].CurrentVirtualTime.minutes === currentOrders[i].ReportTime.minutes) {
                            currentOrders[i].ReportTime = null;
                        }
                    }
                }
            }
            return currentOrders
        } else {
            return []
        }
    } else {
        return []
    }

}

export default formatCurrentOrders