import { motion } from 'framer-motion/dist/framer-motion'
import React, { Component } from 'react'
import './TagsPopup.css'

export default class TagsPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayedTags: props.tags,
            searchString: "",
            showAllTags: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tags !== this.props.tags) {
            this.updateTags()
        }
    }

    updateTags = () => {
        let tags = [...this.props.tags]
        let displayedTags = []
        tags.forEach((tag, index) => {
            if (tag.tag.toLowerCase().includes(this.state.searchString.toLowerCase()))
                displayedTags.push(tag)
        })
        this.setState({displayedTags})
    }

    updateSearch = (e) => {
        let tags = [...this.props.tags]
        let displayedTags = []
        tags.forEach((tag, index) => {
            if (tag.tag.toLowerCase().includes(e.target.value.toLowerCase()))
                displayedTags.push(tag)
        })
        this.setState({searchString: e.target.value, displayedTags})
    }

    selectTag = (tagId) => {
        this.props.selectTag(tagId)
    }

    render() {
        return (
            <div className='tagsPopupPageContainer'>
                <motion.div
                    className='tagsPopupBackdrop'
                    key='tagsPopupBackdrop'
                    onClick={this.props.closePopup}
                    initial={this.props.animationsDisabled ? false : {opacity: 0}}
                    animate={this.props.animationsDisabled ? false : {opacity: 1}}
                    exit={this.props.animationsDisabled ? false : {opacity: 0}}
                    transition={{duration: 0.2}}
                />
                <motion.div
                    className='tagPopupContainer'
                    key='tagsPopupContainer'
                    initial={this.props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
                    animate={this.props.animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
                    exit={this.props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
                    transition={{duration: 0.2}}
                >
                    <button className='popupCloseButton' onClick={this.props.closePopup} />
                    <h3 className='tagPopupTitle'>Add Tags</h3>
                    <p className='tagPopupDescription'>Begin typing a word and select from our list of tags. Selected tags will show up in your filter panel for you to toggle on and off.</p>
                    <input className='tagPopupInput' value={this.state.searchString} onChange={(e) => this.updateSearch(e)} placeholder="Ex: Fever, Headache, Cough, etc…" />
                    <div className='tagPopupTagWrapper'>
                        {this.state.displayedTags.map((tag, index) => (
                            tag.selected &&
                                <div
                                    className={`tagPopupTagContainer ${tag.selected ? 'tagPopupContainerSelected' : ''}`}
                                    key={`${tag.tag} - ${index}`}
                                    onClick={() => this.selectTag(tag.tagId)}
                                >
                                    {tag.selected && <button className='tagPopupTagButton' />}
                                    <p className='tagPopupTagText'>{tag.tag}</p>
                                </div>
                        ))}
                        {this.state.displayedTags.map((tag, index) => (
                            (index <= 25 || this.state.showAllTags) && !tag.selected &&
                                <div
                                    className={`tagPopupTagContainer ${tag.selected ? 'tagPopupContainerSelected' : ''}`}
                                    key={`${tag.tag} - ${index}`}
                                    onClick={() => this.selectTag(tag.tagId)}
                                >
                                    {tag.selected && <button className='tagPopupTagButton' />}
                                    <p className='tagPopupTagText'>{tag.tag}</p>
                                </div>
                        ))}
                        {this.state.displayedTags.length > 25 && !this.state.showAllTags &&
                            <div className='tagPopupTagContainer' onClick={() => this.setState({showAllTags: true})}>
                                <p className='tagPopupTagText'>{this.state.displayedTags.length - 25} More Tags...</p>
                            </div>
                        }
                        {this.state.displayedTags.length === 0 &&
                            <div className='tagPopupTagContainer' style={{cursor: 'default'}}>
                                <p className='tagPopupTagText'>No Tags Match Your Search</p>
                            </div>
                        }
                    </div>
                    <div className='tagPopupFooterWrapper'>
                        <div className='tagPopupCountWrapper'>
                            <h3 className='tagPopupCountText'>Tags Selected: {this.props.tagsSelected}</h3>
                        </div>
                        <button className='tagPopupButton tertiaryButton' onClick={this.props.closePopup}>Close</button>
                    </div>
                </motion.div>
            </div>
        )
    }
}
