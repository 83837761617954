import React from 'react'
import './AverageAttemptByCategory.css'

export default function AverageAttemptByCategory(props) {
    
    const getCategoryValue = (category, dataElement) => {
        let categoryValue

        props.data[dataElement].forEach((value, index) => {
            if (value.CaseCategory === category) {
                categoryValue = value.AverageGrade
            }
        })

        if (isNaN(categoryValue)) {
            return 0
        }

        return Number(categoryValue).toFixed(2)
    }

    return (
        <div className='reportCardModuleContainer averageAttemptByCategoryModuleContainer'>
            <div className='reportCardModuleHeaderContainer'>
                <h2 className='reportCardModuleHeaderText'>Average Attempt By Category</h2>
            </div>
            <div className='averageAttemptByCategoryBodyWrapper'>
                <div className='averageAttemptByCategoryTitleContainer'>
                    <h3 className='averageAttemptByCategoryTitleText' style={{justifySelf: 'start', paddingLeft: 10}}>Category</h3>
                    <h3 className='averageAttemptByCategoryTitleText'>Your Avg First Attempt</h3>
                    <h3 className='averageAttemptByCategoryTitleText'>All Users' Avg First Attempt</h3>
                    <h3 className='averageAttemptByCategoryTitleText'>Your Avg Best Attempt</h3>
                    <h3 className='averageAttemptByCategoryTitleText'>Your Avg All Attempts</h3>
                </div>
                {props.data.OverallAverageGradeAttempt.map((value, index) => (
                    <div className='averageAttemptByCategoryBodyContainer' key={`attemptCategory-${index}`}>
                        <p className='averageAttemptByCategoryBodyText' style={{justifySelf: 'start', paddingLeft: 10, textAlign: 'start'}}>{value.CaseCategory}</p>
                        <p className='averageAttemptByCategoryBodyText'>{getCategoryValue(value.CaseCategory, "OverallFirstGradeAttempt")}%</p>
                        <p className='averageAttemptByCategoryBodyText'>{getCategoryValue(value.CaseCategory, "OverallGeneralUserGradeAttempt")}%</p>
                        <p className='averageAttemptByCategoryBodyText'>{getCategoryValue(value.CaseCategory, "OverallBestGradeAttempt")}%</p>
                        <p className='averageAttemptByCategoryBodyText'>{Number(value.AverageGrade).toFixed(2)}%</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
