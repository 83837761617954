import React from 'react'
import './NewCaseSelectorPopup.css'
import Image from '../images/NewCaseSelectorPopupImage.png'
import { motion } from 'framer-motion/dist/framer-motion'
import MDSpinner from 'react-md-spinner'

export default function NewCaseSelectorPopup(props) {
  return (
    <div className='newCaseSelectorPopupPageContainer'>
        <motion.div
            className='newCaseSelectorPopupBackdrop'
            initial={props.animationsDisabled ? false : {opacity: 0}}
            animate={props.animationsDisabled ? false : {opacity: 1}}
            exit={props.animationsDisabled ? false : {opacity: 0}}
            transition={{duration: 0.2}}
            key="newCaseSelectorPopupBackdrop"
        />
        <motion.div
            className='newCaseSelectorPopupContainer'
            initial={props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
            animate={props.animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
            exit={props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
            transition={{duration: 0.2}}
            key="newCaseSelectorPopupContainer"
        >
            <img className='newCaseSelectorPopupImage' src={Image} alt="Preview of the new case selector"/>
            <div className='newCaseSelectorPopupBodyWrapper'>
                <h2 className='newCaseSelectorPopupTitle'>New Case Selector</h2>
                <p className='newCaseSelectorPopupDescription'>
                    We're very excited to announce our new case selector tool! This upgrade to the case selection page is meant to help you navigate the cases available so you can study more effectively.<br /><br />Would you like to enable the new Case Selector? (You can always change your mind in the settings later)
                </p>
                <div className='newCaseSelectorPopupButtonContainer'>
                    {props.cancelLoading ?
                        <div className='newCaseSelectorPopupSpinnerContainer'>
                            <MDSpinner
                                className='confirmationPopupSpinner'
                                size={30}
                                singleColor={props.primaryColor}
                            />
                        </div>
                    : 
                        <button className='newCaseSelectorPopupButton secondaryButton' onClick={() => props.seenCaseSelectorHandler(false)}>No Thanks</button>
                    }
                    {props.confirmLoading ?
                        <div className='newCaseSelectorPopupSpinnerContainer'>
                            <MDSpinner
                                className='confirmationPopupSpinner'
                                size={30}
                                singleColor={props.primaryColor}
                            />
                        </div>
                    : 
                        <button className='newCaseSelectorPopupButton tertiaryButton' onClick={() => props.seenCaseSelectorHandler(true)}>Enable</button>
                    }
                </div>
            </div>
        </motion.div>
    </div>
  )
}
