//getActionLogData takes the action log and loops through it, determining the last action log event time by checking each action's
//time and only considering it if it is within 20 minutes of the start time

import getDaysFromDate from "../GetDaysFromDate"

//We then check for patient updates and, if they exist, format them accordingly

//Then we check for physical results and push them to the array of results

//Then we check for vitals information and push it to the vitals array

//Finally we subtract actionLogStart from actionLogEnd to determine the CurrentActualTime and return the patientUpdates array,
//currentActualTime and physicalResults array
const getActionLogData = (actionLog) => {
    let actionLogStart
    let actionLogEnd
    let currentActualTime
    let patientUpdates = []
    let physicalResults = []
    let vitalsResults = []
    if (Object.keys(actionLog[0]).length === 0 && actionLog[0].constructor === Object) {
        return { patientUpdates: [], currentActualTime: null, physicalResults: [], vitalsResults: [] }
    }
    if (actionLog.length > 0) {
        actionLogStart = new Date(actionLog[0].CurrentActualTime)
        actionLog.forEach((actionValue, actionIndex) => {

            // Determine if date is in required timeframe
            let actionDate = new Date(actionValue.CurrentActualTime)
            let dateDifference = new Date(actionDate - actionLogStart)
            if (dateDifference.getMinutes() <= 20) {
                actionLogEnd = actionDate
            }

            //Check for patient updates
            if (actionValue.PatientUpdate) {
                let reportTime = new Date(`${actionValue.CurrentVirtualTime}Z`)

                let newActionValue = {...actionValue}

                newActionValue.CurrentVirtualTime = {
                    days: getDaysFromDate(reportTime),
                    hours: reportTime.getUTCHours(),
                    minutes: reportTime.getUTCMinutes()
                }

                patientUpdates.push(newActionValue)
            }
            // if (actionValue.EventName.includes('Returned vitals that was set to a frequency') || actionValue.EventName.includes('Ordered: Vital signs')) {
            if (actionValue.EventName.includes('Returned vitals that was set to a frequency')) {
                let logOrders = {...actionValue.LogOrders}
                let reportTime = new Date(`${logOrders.ReportTime}Z`)
                let timeOrdered = new Date(`${logOrders.TimeOrdered}Z`)
                logOrders.ReportTime = {
                    days: getDaysFromDate(reportTime),
                    hours: reportTime.getUTCHours(),
                    minutes: reportTime.getUTCMinutes()
                }
                logOrders.TimeOrdered = {
                    days: getDaysFromDate(timeOrdered),
                    hours: timeOrdered.getUTCHours(),
                    minutes: timeOrdered.getUTCMinutes()
                }
                vitalsResults.push(logOrders)
            }

            //Check for physical results
            if (actionValue.EventName.includes('Ordered the following Physical Exams and/or patient updates:')) {
                let physicalResult = actionValue.peo
                let reportTime = new Date(`${physicalResult.ReportTime}Z`)
                let currentVirtualTime = new Date(`${physicalResult.CurrentVirtualTime}Z`)
                physicalResult.timeOrdered = {
                    days: getDaysFromDate(currentVirtualTime),
                    hours: currentVirtualTime.getUTCHours(),
                    minutes: currentVirtualTime.getUTCMinutes()
                }
                physicalResult.timeReported = {
                    days: getDaysFromDate(reportTime),
                    hours: reportTime.getUTCHours(),
                    minutes: reportTime.getUTCMinutes()
                }
                physicalResults.push(physicalResult)
            }
        })

        currentActualTime = new Date(actionLogEnd - actionLogStart)
    }
    
    return { patientUpdates, currentActualTime, physicalResults, vitalsResults }
}

export default getActionLogData